<template>
  <div class="page">
      <Header />
      <h1>Privacy Policy</h1>

      <h6>Last Modified April 10th, 2022</h6>

      <h3>We at Dan's Tech Services take the privacy of our customers very seriously and maintain strict policies on how your data is used within our company</h3>

      <div class = "info">Dan's Tech Services and our techs do not sell or use your personal information for any other purpose than those strictly required to operate</div>
      <div class = "info">By using any of our services or programs you are agreeing to our Privacy Policy and consenting to the storage of your personal information</div>
      <div class = "info">We collect the following personal information and store it for use in helping you, as well as for programs like PC Refresh and My Repair:</div>
      <ul>
          <li>First Name</li>
          <li>Last Name</li>
          <li>Phone Number</li>
          <li>Email</li>
          <li>Address</li>
          <li>Contact Preferences</li>
      </ul>
      <div class = "info">Additionally we may store interactions with you in comments on work orders or in recorded formats when appropriate</div>
      <div class = "info">You may have the option to store their payment methods in order to expedite future payments or autopay for programs such as PC Refresh</div>
      <div class = "info">Our payment processor handles the collection and storage of payment information</div>
      <div class = "info">We currently use Square (Block, Inc.) for payment processing and you can find their privacy policy <a href = "https://squareup.com/us/en/legal/general/privacy-no-account" target="_blank">here</a></div>
      <div class = "info">You can at any time request a copy of all the personal information that we have on record for you and our team will provide it within a reasonably timely manner</div>
      <div class = "info">You may also request that your personal information be removed from our systems - Understanding that in doing so you will not be able to utilize our services or programs again without consenting to the storage of your information</div>
      <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Privacy',
  components:{
      Header,
      Footer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #e6e6e6;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

.info{
    margin: 20px;
}
</style>